<template>
  <div class="user-management">
    <div class="card p-6 mb-8">
      <div class="card-header cursor-pointer px-0 pt-2 pb-6 mb-6">
        <div class="card-title m-0 mb-lg-0 mb-5 me-3 ms-5">
          <h3 class="fw-bolder m-0 ">{{ $t("Admins") }}</h3>
          <span class="text-with-bg ms-3">{{ bankDetails.teamUsers.admins.length }}</span>
        </div>
        <div class="search-input">
          <img src="/media/buying/icons/search-black.svg" alt="">
          <el-input v-model="adminSearch" :placeholder="$t('Search Admins')"></el-input>
        </div>
      </div>

      <BankTeamsTable
        :showRole="true"
        :teams-data="adminsData"
        :authUser="userInfo"
        :has-access-to-delete="userInfo.can(BankUserPermissionTypeEnum.DELETE_ADMIN)"
        :has-access-to-promote-demote="userInfo.can(BankUserPermissionTypeEnum.PROMOTE_DEMOTE_USER)"
        :has-access-to-edit="userInfo.can(BankUserPermissionTypeEnum.EDIT_ADMIN) || userInfo.can(BankUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS)"
        @handle-view="viewUser"
        @handle-edit="editUser"
        @handle-view-profile="viewAuthUserProfile"
        @handleUserRoleChange="handleUserRoleChange"
      />
    </div>

    <div class="card p-6 mb-8">
      <div class="card-header cursor-pointer px-0 pt-2 pb-6 mb-6">
        <div class="card-title m-0 mb-lg-0 mb-5 me-3 ms-5">
          <h3 class="fw-bolder m-0 ">{{ $t("Users") }}</h3>
          <span class="text-with-bg ms-3">{{ bankDetails.teamUsers.users.length }}</span>
        </div>
        <div class="search-input">
          <img src="/media/buying/icons/search-black.svg" alt="">
          <el-input v-model="userSearch" :placeholder="$t('Search user')"></el-input>
        </div>
      </div>

      <BankTeamsTable
        :showRole="false"
        :teams-data="usersData"
        :authUser="userInfo"
        :has-access-to-delete="userInfo.can(BankUserPermissionTypeEnum.DELETE_USER)"
        :has-access-to-promote-demote="userInfo.can(BankUserPermissionTypeEnum.PROMOTE_DEMOTE_USER)"
        :has-access-to-edit="userInfo.can(BankUserPermissionTypeEnum.EDIT_USER) || userInfo.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)"
        @handle-view="viewUser"
        @handle-edit="editUser"
        @handle-view-profile="viewAuthUserProfile"
        @handleUserRoleChange="handleUserRoleChange"
      />

      <div class="see-all-users" v-if="bankDetails.teamUsers.users.length > 8" @click="seeAllUsers = !seeAllUsers">
        <span v-if="!seeAllUsers">{{ $t('See All') }} ({{ bankDetails.teamUsers.users.length - 8 }})</span>
        <span v-else>{{ $t('Close') }}</span>
      </div>
    </div>
  </div>

  <ConfirmationModal
    title="Deleting User"
    description="Are you sure you want to delete this user?"
    btnName="Delete"
    :popupVisible="visibleDeleteModal"
    @handleClose="visibleDeleteModal = false"
    @handleConfirm="deleteUser"
  />
</template>

<script>
import { BankSettingsContainer } from "@/store/models/bank/BankSettingsContainer";
import BankTeamsTable from "@/buying-teams/pages/bank/teams/components/BankTeamsTable";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";
import store from "@/store";
import BankTeamUserOperationEnum from "@/store/enums/bank/BankTeamUserOperationEnum";
import NotificationService from "@/buying-teams/services/NotificationService";
import ConfirmationModal from "@/buying-teams/shared-components/modals/ConfirmationModal";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
  name: "UserManagement",
  components: {
    ConfirmationModal,
    BankTeamsTable
  },
  props: {
    bankDetails: BankSettingsContainer
  },
  data() {
    return {
      adminSearch: '',
      userSearch: '',
      seeAllUsers: false,
      visibleDeleteModal: false,
      deletedUserData: null,
      BankUserPermissionTypeEnum
    }
  },
  methods: {
    viewUser(id) {
      this.$router.push("/bank/teams/user/" + id);
    },
    editUser(id) {
      this.$router.push(`/bank/teams/user/${id}?edit=true`);
    },
    viewAuthUserProfile() {
      redirectToRouteWithName('bank-user-setting-overview');
    },
    handleUserRoleChange({data, is_admin}) {
      if (data.operation === BankTeamUserOperationEnum.REMOVE) {
        this.deletedUserData = {data, is_admin};
        this.visibleDeleteModal = true;
      } else {
        this.handleDoUserOperation(data).then(() => {
          if (data.operation === BankTeamUserOperationEnum.PROMOTE) {
            this.bankDetails.promoteUser(data.user_id);
          } else if (data.operation === BankTeamUserOperationEnum.DEMOTE) {
            this.bankDetails.demoteUser(data.user_id);
          }
        })
      }
    },
    handleDoUserOperation(data) {
      return new Promise((resolve) => {
        store.dispatch('updateTeamUser', data)
        .then(() => {
          NotificationService.swalCustom({
            title: "Success",
            icon: "success"
          });
          resolve();
        })
      })
    },
    deleteUser() {
      this.handleDoUserOperation(this.deletedUserData.data).then(() => {
        this.bankDetails.removeUser(this.deletedUserData.data.user_id, this.deletedUserData.is_admin);
        this.visibleDeleteModal = false;
        this.deletedUserData = null;
      })
    }
  },
  computed: {
    adminsData() {
      return this.bankDetails.getFilteredUsers('admins', this.adminSearch)
    },
    usersData() {
      return this.seeAllUsers
        ? this.bankDetails.getFilteredUsers('users', this.userSearch)
        : this.bankDetails.getFilteredUsers('users', this.userSearch).slice(0, 8)
    },
    userInfo() {
      return store.getters.currentUser
    }
  }
}
</script>

<style lang="scss">
.user-management {
  .search-input {
    position: relative;
    img {
      position: absolute;
      left: 26px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      filter: invert(58%) sepia(5%) saturate(20%) hue-rotate(12deg) brightness(93%) contrast(94%);
    }
    .el-input__inner {
      border: 1px solid #E5E5E5;
      border-radius: 26px;
      background: transparent;
      padding-left: 47px;
      height: 43px;
      color: #2B2B2B;
      font-size: 16px;

      &::placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #8B8B8B;
      }

      @media (min-width: 400px) {
        width: 324px;
      }
    }
  }
  .card {
    border-radius: 17px;
  }
  .see-all-users {
    background: #F6F7FF;
    border-radius: 0px 0px 17px 17px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #435BF4;
    padding: 20px;
    margin: 0 -1.5rem -1.5rem;
    cursor: pointer;
    text-align: center;
  }
}
</style>
